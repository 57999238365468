import { useEffect } from 'react'

const push = values => {
  if (!global.$crisp) return
  global.$crisp.push(values)
}

function useCrisp() {
  useEffect(() => {
    if (typeof window === 'undefined') return
    // Prevent duplicate instances of Crisp
    const scriptUrl = 'https://client.crisp.chat/l.js'
    const scripts = document.querySelector(`script[src='${scriptUrl}']`)
    if (scripts !== null) return
    // Add Crisp
    global.$crisp = []
    global.CRISP_WEBSITE_ID = 'c0b25025-dce5-4e32-ba4f-fdd427e205ef'
    ;(function () {
      var d = document
      var s = d.createElement('script')

      s.src = scriptUrl
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
    // Set safe mode to suppress errors
    push(['safe', true])
  }, [])
  const open = () => push(['do', 'chat:open'])
  const close = () => push(['do', 'chat:close'])
  return { open, close }
}

export default useCrisp
