import { forwardRef, Ref } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'

import ENV_VARS from '@/config'
import structure from '@/structure'
import { breakpoints, colors } from '@/styles'
import { toUnit } from '@/utils/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { AndroidButton, AppleButton } from '@/components/app-buttons'

import LanguageSelector from './language-selector'
import { ReactComponent as FacebookIcon } from './images/Facebook.svg'
import { ReactComponent as InstagramIcon } from './images/Instagram.svg'
import { ReactComponent as LivLogo } from './images/liv-rent-logo.svg'
import { ReactComponent as TwitterIcon } from './images/Twitter.svg'

const { DASHBOARD_URL } = ENV_VARS

const DetailStyle = css`
  @media (max-width: 1099px) {
    margin-bottom: 94px;
    width: 100%;
    display: inline-block;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 0px;
    margin-bottom: 55px;
  }
`

const Wrapper = styled.footer<{
  minimal?: boolean
  pushDown?: boolean
  hideOnMobile?: boolean
  detail?: boolean
}>`
  position: ${({ minimal, pushDown }) =>
    minimal && !pushDown ? 'fixed' : 'relative'};
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  height: auto;
  background-color: ${colors.white};
  margin-top: auto;
  ${({ detail }) => (detail ? DetailStyle : {})}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    ${({ hideOnMobile }) => hideOnMobile && 'display: none'};
  }
`

const FooterContainer = styled.div<{
  minimal?: boolean
}>`
  padding-top: ${({ minimal }) => (minimal ? 0 : 64)}px;
  background-color: #181818;
  z-index: 1;

  @media screen and (max-width: 900px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const InnerFooterContainer = styled.div<{
  minimal?: boolean
}>`
  margin-left: auto;
  margin-right: auto;
  max-width: 1096px;
  margin-bottom: 0px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: ${({ minimal }) => (minimal ? 0 : 64)}px;
  padding-top: 0;

  @media screen and (max-width: 900px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-width: initial;
    width: 80%;
  }
`

const LinkContainer = styled.div`
  border-bottom: 1px solid #888;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-top: 30px;
    flex-direction: row;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 900px) {
    width: 500px;
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    width: 100%;
  }
`

const PrimaryLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 475px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    gap: 20px;
  }
`

const StackLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    align-items: flex-start;
    width: 144px;
  }
`

const linkStyle = css`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
  color: ${colors.white};
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  margin: 0;

  &:hover {
    color: ${colors.primary};
  }
`

const StyledLink = styled.a`
  ${linkStyle}
`

const StyledAnchor = styled.a`
  ${linkStyle}
`

const LivLogoLink = styled.a`
  svg {
    flex-shrink: 0;
  }
`

const AppDownloadLinks = styled.div`
  display: flex;
  flex-direction: column;

  a:first-child {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 900px) {
    padding-top: 30px;
    flex-direction: row;

    a:first-child {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-top: 30px;
    flex-direction: row;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SocialLinks = styled.div`
  display: flex;

  @media screen and (max-width: 900px) {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    align-items: flex-end;
    text-align: center;
  }

  @media screen and (max-width: 479px) {
    margin-top: 16px;
    justify-content: space-around;
  }
`

const IconLink = styled.a`
  margin-right: 13px;
  margin-left: 13px;
  padding: 3px 16px 3px 3px;
  opacity: 1;
  transition: opacity 150ms ease;
  flex-shrink: 0;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 900px) {
    padding-right: 16px;
  }
`

const LastIconLink = styled(IconLink)`
  margin-right: 0px;
  padding: 3px 3px 3px 3px;

  @media screen and (max-width: 900px) {
    margin-right: 13px;
  }
`

const SubFooter = styled.div<{
  minimal?: boolean
}>`
  margin-top: ${({ minimal }) => (minimal ? 0 : 40)}px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0 env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px)
    env(safe-area-inset-left, 0px);
  align-items: center;

  ${props =>
    props.minimal &&
    `
      align-items: center;
      height: ${toUnit(structure.footer.minimal.height)};
    `}

  @media screen and (max-width: 770px) {
    ${props =>
      !props.minimal &&
      `
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
      `}
  }

  @media screen and (max-width: 598px) {
    ${props =>
      !props.minimal &&
      `
    margin-top: 25px;
    flex-direction: column;
    align-items: center;`}
  }
`

const CopyrightText = styled.p<{
  minimal?: boolean
}>`
  color: ${colors.white};
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  line-height: 18px;
  text-align: left;
  font-weight: 400;
  margin: 0;

  @media screen and (max-width: 598px) {
    padding-top: ${({ minimal }) => (minimal ? 0 : 25)}px;
  }

  @media screen and (max-width: 479px) {
    text-align: center;
  }
`

const SocialLocale = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-items: center;

  @media screen and (max-width: 770px) {
    padding-top: 25px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    flex-direction: column;
    align-items: center;
  }
`

const LocaleBackground = styled.div`
  height: 36px;
  width: 144px;
  border-radius: 24px;
  text-align: center;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 375px) {
    width: 120px;
  }
`

const LocalePicker = styled.div`
  color: ${colors.white};
  display: inline-block;
`

const currentYear = new Date().getFullYear()

type Props = {
  detail?: boolean
  hideOnBreakpoint?: boolean
  className?: string
}

const FooterComponent = forwardRef(
  (
    { detail, className, hideOnBreakpoint, ...rest }: Props,
    ref: Ref<HTMLElement>
  ) => {
    const { t } = useTranslation()
    const router = useRouter()
    const isChinese = router.locale === 'zh'
    const blogUrl = isChinese
      ? 'https://liv.rent/blog/zh/'
      : 'https://liv.rent/blog/'
    const showTablet = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    const isHidden = useMediaQuery(`(max-width: ${hideOnBreakpoint})`)
    if (isHidden) return null

    return (
      <Wrapper detail={detail} className={className} ref={ref} {...rest}>
        {showTablet ? (
          <FooterContainer>
            <InnerFooterContainer>
              <Link href="/" passHref legacyBehavior>
                <LivLogoLink
                  aria-label={t('b.landing.home.label', 'liv.rent Homepage')}
                >
                  <LivLogo height={26} width={57} />
                </LivLogoLink>
              </Link>
              <LinkContainer>
                <PrimaryLinks>
                  <StackLinks>
                    <Link
                      href="/about"
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <StyledLink>{t('About')}</StyledLink>
                    </Link>
                    <StyledAnchor href={blogUrl}>
                      {t('b.footer.view.blog.link', 'Blog')}
                    </StyledAnchor>
                    <Link
                      href="/privacy"
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <StyledLink>{t('Privacy')}</StyledLink>
                    </Link>
                    <StyledAnchor
                      href={DASHBOARD_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('b.footer.view.sign_contract.link', 'Sign Contract')}
                    </StyledAnchor>
                    <a
                      href="https://apps.apple.com/ca/app/liv-apartment-houses-rental/id1321741040"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppleButton height={45} />
                    </a>
                  </StackLinks>
                  <StackLinks>
                    <Link href="/faq" passHref prefetch={false} legacyBehavior>
                      <StyledLink>{t('FAQ')}</StyledLink>
                    </Link>
                    <Link
                      href="/terms"
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <StyledLink>{t('Terms')}</StyledLink>
                    </Link>
                    <StyledAnchor href="https://angel.co/liv-rent/jobs">
                      {t('b.footer.view.join_us.link', 'Join Us')}
                    </StyledAnchor>
                    <StyledAnchor
                      href="https://verify.liv.rent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('b.footer.view.verify_signing.link', 'Verify Signing')}
                    </StyledAnchor>
                    <a
                      href={
                        'https://play.google.com/store/apps' +
                        '/details?id=rent.liv.ether'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AndroidButton height={45} />
                    </a>
                  </StackLinks>
                </PrimaryLinks>
              </LinkContainer>
              <SubFooter>
                <CopyrightText>
                  Liv Strategies Inc. © {currentYear} | Vancouver, BC |{' '}
                  {t(
                    'b.footer.view.apartments_for_rent.text',
                    'Apartments for Rent'
                  )}
                </CopyrightText>
                <SocialLocale>
                  <LocaleBackground>
                    <LocalePicker>
                      <LanguageSelector clickId="footer" />
                    </LocalePicker>
                  </LocaleBackground>
                  <SocialLinks>
                    <IconLink
                      href="https://twitter.com/liv_rent"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter"
                    >
                      <TwitterIcon height={24} width={24} />
                    </IconLink>
                    <IconLink
                      href="https://www.instagram.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram"
                    >
                      <InstagramIcon height={24} width={24} />
                    </IconLink>
                    <LastIconLink
                      href="https://www.facebook.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <FacebookIcon height={24} width={24} />
                    </LastIconLink>
                  </SocialLinks>
                </SocialLocale>
              </SubFooter>
            </InnerFooterContainer>
          </FooterContainer>
        ) : (
          <FooterContainer>
            <InnerFooterContainer>
              <LinkContainer>
                <LeftContainer>
                  <PrimaryLinks>
                    <Link href="/" passHref legacyBehavior>
                      <LivLogoLink
                        aria-label={t(
                          'b.landing.home.label',
                          'liv.rent Homepage'
                        )}
                      >
                        <LivLogo height={26} width={57} />
                      </LivLogoLink>
                    </Link>
                    <StackLinks>
                      <Link href="/about" passHref legacyBehavior>
                        <StyledLink>{t('About')}</StyledLink>
                      </Link>
                      <StyledAnchor href={blogUrl}>
                        {t('b.footer.view.blog.link', 'Blog')}
                      </StyledAnchor>
                      <Link href="/privacy" passHref legacyBehavior>
                        <StyledLink>{t('Privacy')}</StyledLink>
                      </Link>
                    </StackLinks>
                    <StackLinks>
                      <Link href="/faq" passHref legacyBehavior>
                        <StyledLink>{t('FAQ')}</StyledLink>
                      </Link>
                      <Link href="/terms" passHref legacyBehavior>
                        <StyledLink>{t('Terms')}</StyledLink>
                      </Link>
                      <StyledAnchor href="https://angel.co/liv-rent/jobs">
                        {t('b.footer.view.join_us.link', 'Join Us')}
                      </StyledAnchor>
                    </StackLinks>
                    <StackLinks>
                      <StyledAnchor
                        href={DASHBOARD_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('b.footer.view.sign_contract.link', 'Sign Contract')}
                      </StyledAnchor>
                      <StyledAnchor
                        href="https://verify.liv.rent"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t(
                          'b.footer.view.verify_signing.link',
                          'Verify Signing'
                        )}
                      </StyledAnchor>
                    </StackLinks>
                  </PrimaryLinks>
                </LeftContainer>
                <RightContainer>
                  <AppDownloadLinks>
                    <a
                      href="https://apps.apple.com/ca/app/liv-apartment-houses-rental/id1321741040"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppleButton height={45} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=rent.liv.ether"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AndroidButton height={45} />
                    </a>
                  </AppDownloadLinks>
                </RightContainer>
              </LinkContainer>
              <SubFooter>
                <CopyrightText>
                  Liv Strategies Inc. © {currentYear} | Vancouver, BC |{' '}
                  {t(
                    'b.footer.view.apartments_for_rent.text',
                    'Apartments for Rent'
                  )}
                </CopyrightText>
                <SocialLocale>
                  <LocaleBackground>
                    <LocalePicker>
                      <LanguageSelector clickId="footer" />
                    </LocalePicker>
                  </LocaleBackground>
                  <SocialLinks>
                    <IconLink
                      href="https://twitter.com/liv_rent"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter"
                    >
                      <TwitterIcon height={24} width={24} />
                    </IconLink>
                    <IconLink
                      href="https://www.instagram.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram"
                    >
                      <InstagramIcon height={24} width={24} />
                    </IconLink>
                    <LastIconLink
                      href="https://www.facebook.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <FacebookIcon height={24} width={24} />
                    </LastIconLink>
                  </SocialLinks>
                </SocialLocale>
              </SubFooter>
            </InnerFooterContainer>
          </FooterContainer>
        )}
      </Wrapper>
    )
  }
)

FooterComponent.displayName = 'FooterComponent'

export type FooterProps = {
  visible?: boolean
  detail?: boolean
  hideOnBreakpoint?: boolean
  hideOnMobile?: boolean
  pushDownOnMobile?: boolean
  theme?: string
  className?: string
}

const Footer: React.FC<FooterProps> = forwardRef(
  (
    {
      visible = true,
      detail = false,
      className,
      hideOnMobile,
      theme,
      pushDownOnMobile = true,
      ...rest
    },
    ref: Ref<HTMLElement>
  ) => {
    const { t } = useTranslation()

    const displayMobile = useMediaQuery(
      `(max-width: ${breakpoints.ipadMiniMax})`
    )
    if (!visible) return null
    const copyrightText = displayMobile
      ? `Liv Strategies Inc. © ${currentYear}`
      : `Liv Strategies Inc. © ${currentYear} | ` +
        `Vancouver, BC | ${t(
          'b.footer.view.apartments_for_rent.text',
          'Apartments for Rent'
        )}`

    if (theme === 'minimal')
      return (
        <Wrapper
          detail={detail}
          minimal
          hideOnMobile={hideOnMobile}
          pushDown={pushDownOnMobile}
          className={className}
          {...rest}
        >
          <FooterContainer minimal>
            <InnerFooterContainer minimal>
              <SubFooter minimal>
                <CopyrightText minimal>{copyrightText}</CopyrightText>
                <LanguageSelector clickId="footer" />
              </SubFooter>
            </InnerFooterContainer>
          </FooterContainer>
        </Wrapper>
      )

    return (
      <FooterComponent
        detail={detail}
        className={className}
        ref={ref}
        {...rest}
      />
    )
  }
)

Footer.displayName = 'Footer'

export default Footer
