import dynamic from 'next/dynamic'

import { createModal } from '@/components/modalize'

const AgreementModal = dynamic(() => import('./agreement-modal-content'))

export const showAgreementModal = createModal(AgreementModal, {
  width: 450,
  showClose: false,
  clickOutside: false
})
