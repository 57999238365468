import MainContainer, {
  MainContainerProps
} from '@/features/Structure/main-container'

interface Props {
  children: React.ReactNode
}
export function makeMainLayout(config?: MainContainerProps) {
  const MainLayout: React.FC<Props> = ({ children }) => {
    return <MainContainer {...config}>{children}</MainContainer>
  }
  return MainLayout
}
