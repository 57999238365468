import { useEffect } from 'react'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import { isEmptyOrNull } from '@/utils'
import useSessionComplete from '@/hooks/use-session-complete'

import { showAgreementModal } from './agreement-modal'

const AgreementModal = () => {
  const dispatch = useDispatch()
  const currentAgreement = useSelector(path(['agreement', 'current']))
  const mismatched = useSelector(path(['agreement', 'mismatched']))
  const mismatchLength = Object.keys(mismatched).length
  const hasSession = useSessionComplete()

  useEffect(() => {
    const load = async () => {
      await dispatch.agreement.getCurrentAgreements()
      dispatch.agreement.validateAgreements()
    }
    if (hasSession && isEmptyOrNull(currentAgreement)) {
      load()
    }
  }, [dispatch.agreement, dispatch.session, hasSession, currentAgreement])

  useEffect(() => {
    if (hasSession && mismatchLength > 0) {
      showAgreementModal()
    }
  }, [hasSession, mismatchLength])

  return null
}

export default AgreementModal
