import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styled, { createGlobalStyle } from 'styled-components'

import { colors } from '@/styles'
import { useDispatch } from '@/lib/redux'
import handleSession from '@/utils/handle-session'
import useCurrentSession from '@/hooks/use-current-session'
import { show as showLoginModal } from '@/features/Auth/login-modal'
import Footer, { FooterProps } from '@/features/Footer'
import Header, { HeaderProps, HeaderTheme } from '@/features/Header'
import AgreementModal from '@/features/Terms/agreement'
import CookieBanner from '@/components/cookie-banner'
import SupportOverlay from '@/components/support-overlay'
import useEventListener from '@/components/use-event-listener'

const Container = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${props => props.background || colors.white};
`

const DisableZoom = createGlobalStyle`
  html, body {
    touch-action: pan-x pan-y;
  }
`

export interface MainContainerProps {
  children?: React.ReactNode
  headerConfig?: HeaderProps
  footerConfig?: FooterProps
  supportConfig?: {}
  disableZoom?: boolean
  suppressRegisterModal?: boolean
  background?: string
}

const MainContainer: React.FC<MainContainerProps> = ({
  children,
  headerConfig = {
    theme: HeaderTheme.DEFAULT
  },
  footerConfig = {},
  supportConfig = {},
  disableZoom = false,
  suppressRegisterModal,
  ...rest
}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const session = useCurrentSession()
  const { t } = useTranslation()

  useEffect(() => {
    if (session && session.uuid) {
      dispatch.sse.subscribe(session.uuid)
    }
    if (session?.registration_complete === '0' && !suppressRegisterModal) {
      showLoginModal({
        mode: 'login',
        t
      })
    }
    return () => dispatch.sse.unsubscribe()
  }, [dispatch.sse, session, suppressRegisterModal, t])

  useEventListener('storage', (e: any) => {
    if (e.key === 'session-id') {
      if (!!e.oldValue && e.oldValue !== e.newValue) {
        dispatch.session.clear()
      } else if (!e.oldValue && !!e.newValue) {
        handleSession(dispatch.session.loadSession())
      }
    }
  })

  return (
    <Container {...rest}>
      {disableZoom && <DisableZoom />}
      <SupportOverlay {...supportConfig} />
      <Header {...headerConfig} />
      <AgreementModal />
      {children}
      <CookieBanner lang={router.locale} privacyPolicyUrl={'/privacy'} />
      <Footer {...footerConfig} />
    </Container>
  )
}

export default MainContainer
