import Image from 'next/legacy/image'
import styled from 'styled-components'

import { colors } from '@/styles'

import { ReactComponent as WechatIcon } from './wechat-icon.svg'
import QRCodeImage from './wechat-qr-code.jpg'

const Wrapper = styled.div`
  display: block;
  position: fixed;
  width: 60px;
  height: 60px;
  margin: 0px 24px 20px 0px;
  left: auto;
  top: auto;
  right: 0px;
  bottom: 0px;
  z-index: 20000;
  transition: opacity 0.5s ease-in-out;
  &[aria-hidden='true'] {
    opacity: 0;
  }
`

const InnerWrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${colors.white};
  border: 2px solid ${colors.white};
  border-radius: 30px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

  :active {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
  }
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
`

const QRWrapper = styled.div`
  position: absolute;
  bottom: 73px;
  right: 0;
  width: 180px;
  background-color: ${colors.white};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  padding: 12px 2px 2px 1px;
`

const Text = styled.p`
  font-size: 14px;
  margin: 0 0 7px;
  text-align: center;
  color: ${colors.darkGrey};
`

const WeChat = ({ setShow, show }) => (
  <Wrapper id="wechat-overlay" aria-hidden={false}>
    <InnerWrapper>
      <Button
        aria-label="WeChat"
        aria-pressed={show}
        onClick={() => setShow(!show)}
      >
        <IconWrapper clickable>
          <WechatIcon width={56} height={56} />
        </IconWrapper>
      </Button>
      {show && (
        <QRWrapper>
          <Text>扫二维码联系微信客服</Text>
          <Image
            src={QRCodeImage}
            width={177}
            height={177}
            placeholder="blur"
            alt="WeChat QR Code"
          />
        </QRWrapper>
      )}
    </InnerWrapper>
  </Wrapper>
)

export default WeChat
