import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import downloadApple from './app-store.png'

const width = height => Math.round((270 / 80) * height)

const AppleButton = ({ height }) => (
  <Image
    src={downloadApple}
    height={height}
    width={width(height)}
    alt="Download liv.rent iOS App"
    placeholder="blur"
  />
)

AppleButton.propTypes = {
  height: PropTypes.number
}

AppleButton.defaultProps = {
  height: 40
}

export default AppleButton
