import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import downloadAndroid from './google-play.png'

const width = height => Math.round((270 / 80) * height)

const AndroidButton = ({ height }) => (
  <Image
    src={downloadAndroid}
    height={height}
    width={width(height)}
    alt="Download liv.rent Android App"
    placeholder="blur"
  />
)

AndroidButton.propTypes = {
  height: PropTypes.number
}

AndroidButton.defaultProps = {
  height: 40
}

export default AndroidButton
