import { useEffect, useRef, useState } from 'react'

import 'vanilla-cookieconsent/dist/cookieconsent.css'

import * as CookieConsent from 'vanilla-cookieconsent'

import { getCountryByIP } from '@/models/auth/ports'

const pushToDataLayer = (event: string) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push({ event })
}

const updateConsent = () => {
  pushToDataLayer('consent_update')
}

const initializeConsent = () => {
  updateConsent()
  setTimeout(() => {
    pushToDataLayer('consent_initial_selection')
  }, 500)
}

const preferencesModal = {
  en: {
    title: 'Privacy Preference Center',
    acceptAllBtn: 'Accept All Cookies',
    acceptNecessaryBtn: 'Reject All',
    savePreferencesBtn: 'Save Preferences',
    closeIconLabel: 'Close modal',
    serviceCounterLabel: 'Service|Services',
    sections: [
      {
        title: 'Cookie Usage',
        description:
          'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is used to make the site work as you expect it to. The information does not directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change your default settings.'
      },
      {
        title:
          'Strictly Necessary Cookies <span class="pm__badge">Always Active</span>',
        description:
          'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences or filling in forms.',
        linkedCategory: 'necessary'
      },
      {
        title: 'Analytics Cookies',
        description:
          'These cookies allow us to track analytics by counting visits and traffic sources so we can measure and improve the performance of our website. They may be set by us or by third party providers to help us to know which pages are the most and least popular and see how visitors move around the website.',
        linkedCategory: 'analytics'
      },
      {
        title: 'Functionality Cookies',
        description:
          'These cookies enable the website to provide enhanced functionality and personalization based on your interaction with the website. They may be set by us or by third party providers whose services we have added to our pages.',
        linkedCategory: 'functionality'
      },
      {
        title: 'Advertisement Cookies',
        description:
          'These cookies may be set through our site by our advertising partners. They may be used by those partners to build a profile of your interests and show you relevant advertisements on other websites.',
        linkedCategory: 'marketing'
      }
    ]
  },
  zh: {
    title: '隐私偏好中心',
    acceptAllBtn: '接受所有 cookie',
    acceptNecessaryBtn: '拒绝所有',
    savePreferencesBtn: '保存偏好',
    closeIconLabel: '关闭模态窗口',
    serviceCounterLabel: 'Service|Services',
    sections: [
      {
        title: 'Cookie 使用',
        description:
          '当您访问任何网站时，它可能会在您的浏览器中存储或检索信息，主要以 cookie 的形式。这些信息可能是关于您、您的偏好或您的设备，并用于使网站按您的期望工作。这些信息并不直接识别您，但它可以为您提供更个性化的 Web 体验。因为我们尊重您的隐私权，您可以选择不允许某些类型的 cookie。单击不同的类别标题以了解更多信息并更改您的默认设置。'
      },
      {
        title: '严格必要的 Cookie <span class="pm__badge">始终活动</span>',
        description:
          '这些 cookie 对于网站的功能是必不可少的，不能在我们的系统中关闭。它们通常只是对您的操作做出的响应，这些操作相当于请求服务，例如设置您的隐私偏好或填写表单。',
        linkedCategory: 'necessary'
      },
      {
        title: '分析 Cookie',
        description:
          '这些 cookie 允许我们通过计算访问量和流量来源来跟踪分析，以便我们可以衡量和改善我们网站的性能。它们可能是由我们设置的，也可能是由第三方提供商设置的，以帮助我们了解哪些页面最受欢迎，哪些页面最不受欢迎，并查看访问者在网站上的移动情况。',
        linkedCategory: 'analytics'
      },
      {
        title: '功能性 Cookie',
        description:
          '这些 cookie 使网站能够根据您与网站的交互提供增强功能和个性化。它们可能是由我们设置的，也可能是由我们添加到我们页面的第三方提供商设置的。',
        linkedCategory: 'functionality'
      },
      {
        title: '广告 Cookie',
        description:
          '这些 cookie 可能会通过我们的网站由我们的广告合作伙伴设置。它们可能会被这些合作伙伴用来建立您的兴趣概要，并在其他网站上向您显示相关广告。',
        linkedCategory: 'marketing'
      }
    ]
  },
  fr: {
    title: 'Centre de préférences de confidentialité',
    acceptAllBtn: 'Accepter tous les cookies',
    acceptNecessaryBtn: 'Rejeter tout',
    savePreferencesBtn: 'Enregistrer les préférences',
    closeIconLabel: 'Fermer la fenêtre modale',
    serviceCounterLabel: 'Service|Services',
    sections: [
      {
        title: 'Utilisation des cookies',
        description:
          'Lorsque vous visitez un site web, il peut stocker ou récupérer des informations sur votre navigateur, principalement sous forme de cookies. Ces informations peuvent concerner vous, vos préférences ou votre appareil et sont utilisées pour que le site fonctionne comme vous le souhaitez. Les informations ne vous identifient pas directement, mais elles peuvent vous offrir une expérience web plus personnalisée. Parce que nous respectons votre droit à la vie privée, vous pouvez choisir de ne pas autoriser certains types de cookies. Cliquez sur les différents en-têtes de catégorie pour en savoir plus et modifier vos paramètres par défaut.'
      },
      {
        title:
          'Cookies strictement nécessaires <span class="pm__badge">Toujours actif</span>',
        description:
          'Ces cookies sont nécessaires au fonctionnement du site web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement définis en réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que le réglage de vos préférences en matière de confidentialité ou le remplissage de formulaires.',
        linkedCategory: 'necessary'
      },
      {
        title: 'Cookies analytiques',
        description:
          'Ces cookies nous permettent de suivre les statistiques en comptant les visites et les sources de trafic, afin que nous puissions mesurer et améliorer les performances de notre site web. Ils peuvent être définis par nous ou par des fournisseurs tiers pour nous aider à savoir quelles pages sont les plus populaires et les moins populaires et à voir comment les visiteurs se déplacent sur le site web.',
        linkedCategory: 'analytics'
      },
      {
        title: 'Cookies de fonctionnalité',
        description:
          'Ces cookies permettent au site web de fournir des fonctionnalités améliorées et une personnalisation en fonction de votre interaction avec le site web. Ils peuvent être définis par nous ou par des fournisseurs tiers dont nous avons ajouté les services à nos pages.',
        linkedCategory: 'functionality'
      },
      {
        title: 'Cookies publicitaires',
        description:
          "Ces cookies peuvent être définis via notre site par nos partenaires publicitaires. Ils peuvent être utilisés par ces partenaires pour établir un profil de vos intérêts et vous montrer des publicités pertinentes sur d'autres sites web.",
        linkedCategory: 'marketing'
      }
    ]
  }
}

const getConfig = ({
  lang,
  privacyPolicyUrl,
  showOptIn
}: {
  lang: string
  privacyPolicyUrl: string
  showOptIn: boolean
}) => {
  if (showOptIn) {
    const optInConfig: CookieConsent.CookieConsentConfig = {
      onFirstConsent: initializeConsent,
      onChange: updateConsent,
      guiOptions: {
        consentModal: {
          layout: 'bar inline',
          position: 'bottom',
          equalWeightButtons: false,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: false
        }
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        functionality: {},
        analytics: {},
        marketing: {}
      },
      language: {
        default: lang,
        translations: {
          en: {
            consentModal: {
              title: 'We Value Your Privacy',
              description: `We and selected third parties use cookies for essential purposes and, with your consent, to enhance functionality, improve experience, measure performance, and provide personalized marketing as detailed in our <a href="${privacyPolicyUrl}">Privacy Policy</a>. Manage your consent by clicking "Accept All," "Reject All," or "Customize Settings." Denying consent may disable related features.`,
              acceptAllBtn: 'Accept All',
              acceptNecessaryBtn: 'Reject All',
              showPreferencesBtn: 'Customize Settings'
            },
            preferencesModal: preferencesModal.en
          },
          zh: {
            consentModal: {
              title: '我们非常重视您的隐私',
              description: `liv.rent使用的一些cookies是由第三方服务商嵌入，但所有类别的cookies都需要获得您的授权，才可以被收集。我们使用cookies来提升您的用户体验，帮助我们为您提供个性化的内容和广告。更多详情，请浏览<a href="${privacyPolicyUrl}">隐私条款</a>。点击“全部接受”、“全部拒绝”、“自定义”设置授权。拒绝同意可能导致网站相关功能不可用。`,
              acceptAllBtn: '全部接受',
              acceptNecessaryBtn: '全部拒绝',
              showPreferencesBtn: '自定义设置'
            },
            preferencesModal: preferencesModal.zh
          },
          fr: {
            consentModal: {
              title: 'Nous valorisons votre vie privée',
              description: `Nous et des tiers sélectionnés utilisons des cookies essentiels et, avec votre consentement, pour améliorer les fonctionnalités, l'expérience, la performance et le marketing personnalisé comme indiqué dans notre <a href="${privacyPolicyUrl}">Politique de confidentialité</a>. Gérez votre consentement avec "Tout accepter", "Tout refuser" ou "Personnaliser les paramètres". Refuser peut désactiver certaines fonctionnalités.`,
              acceptAllBtn: 'Tout accepter',
              acceptNecessaryBtn: 'Tout refuser',
              showPreferencesBtn: 'Personnaliser les paramètres'
            },
            preferencesModal: preferencesModal.fr
          }
        }
      }
    }
    return optInConfig
  } else {
    const optOutConfig: CookieConsent.CookieConsentConfig = {
      onFirstConsent: initializeConsent,
      onChange: updateConsent,
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'bottom left',
          equalWeightButtons: false,
          flipButtons: true
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: false
        }
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        functionality: {
          enabled: true
        },
        analytics: {
          enabled: true
        },
        marketing: {
          enabled: true
        }
      },
      language: {
        default: lang,
        translations: {
          en: {
            consentModal: {
              title: 'We Value Your Privacy',
              description: `<span>We and selected third parties use cookies and similar technologies to enhance functionality, improve your experience, measure performance, and provide personalized marketing as detailed in our <a href="${privacyPolicyUrl}">Privacy Policy</a>.</span><span>You can manage permissions for specific types of cookies by clicking the "Customize" button.</span>`,
              acceptAllBtn: 'Got It',
              showPreferencesBtn: 'Customize'
            },
            preferencesModal: preferencesModal.en
          },
          zh: {
            consentModal: {
              title: '我们非常重视您的隐私',
              description: `<span>liv.rent使用的一些cookies是由第三方服务商嵌入，但所有类别的cookies都需要获得您的授权，才可以被收集。我们使用cookies来提升您的用户体验，帮助我们为您提供个性化的内容和广告。更多详情，请浏览<a href="${privacyPolicyUrl}">隐私条款</a>。</span><span>您可以通过点击“自定义设置”按钮来管理特定类型的Cookie权限。</span>`,
              acceptAllBtn: '继续前进',
              showPreferencesBtn: '自定义设置'
            },
            preferencesModal: preferencesModal.zh
          },
          fr: {
            consentModal: {
              title: 'Nous valorisons votre vie privée',
              description: `<span>Nous et des tiers sélectionnés utilisons des cookies et des technologies similaires pour améliorer les fonctionnalités, l'expérience, la performance et le marketing personnalisé comme indiqué dans notre <a href="${privacyPolicyUrl}">Politique de confidentialité</a>.</span><span>Vous pouvez gérer les permissions pour certains types de cookies en cliquant sur le bouton "Personnaliser".</span>`,
              acceptAllBtn: 'Compris',
              showPreferencesBtn: 'Personnaliser'
            },
            preferencesModal: preferencesModal.fr
          }
        }
      }
    }
    return optOutConfig
  }
}

// List of countries that require opt-in consent due to GDPR or similar regulations.
// This includes countries in the European Union (EU), European Economic Area (EEA),
// European Free Trade Association (EFTA), and UK-GDPR
//
// Use ISO 3166-2 country codes: https://en.wikipedia.org/wiki/ISO_3166-2
//
// EAA users: show opt-in banner
// Non-EAA users: show opt-out banner
//
const EAA_MEMEBER_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // The Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'IS', // Iceland (EEA/EFTA)
  'LI', // Liechtenstein (EEA/EFTA)
  'NO', // Norway (EEA/EFTA)
  'CH', // Switzerland (EFTA)
  'GB' // United Kingdom (UK-GDPR)
]

// To reset clear "cookieConsentShown" in localStorage and "cc_cookie" in cookies
const CookieBanner = ({
  lang = 'en',
  privacyPolicyUrl = '/privacy'
}: { lang?: string; privacyPolicyUrl?: string } = {}) => {
  const loadedRef = useRef(false)
  const [userLocation, setUserLocation] = useState({
    country_code_alpha2: 'CA'
  })

  useEffect(() => {
    if (loadedRef.current) return
    const getUserLocation = async () => {
      try {
        const { body }: { body: { country_code_alpha2: string } } =
          (await getCountryByIP()) || {}
        await setUserLocation(body)
        loadedRef.current = true
      } catch (error) {
        console.error('Error fetching user location:', error)
      }
    }
    getUserLocation()
  }, [])

  const countryCode = userLocation.country_code_alpha2 || 'CA'
  const isEAAMemberCountry = EAA_MEMEBER_COUNTRIES.includes(countryCode)

  useEffect(() => {
    // Only show the cookie banner once if the user is non-EAA
    const consentShown = window.localStorage.getItem('cookieConsentShown')
    const shouldAutoShow = isEAAMemberCountry || !consentShown

    CookieConsent.setLanguage(lang)
    CookieConsent.run({
      autoShow: shouldAutoShow,
      ...getConfig({ lang, privacyPolicyUrl, showOptIn: isEAAMemberCountry })
    })

    window.localStorage.setItem('cookieConsentShown', 'true')
  }, [lang, privacyPolicyUrl, isEAAMemberCountry])

  return null
}

export default CookieBanner
