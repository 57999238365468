import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { createGlobalStyle } from 'styled-components'

import useCrisp from '@/hooks/use-crisp'

import WeChat from './wechat'

const HideCrisp = createGlobalStyle`
  .crisp-client {
    opacity: 0;
    display: none !important;
    z-index: -1 !important;
  }
`

const HideCrispInvalidSite = createGlobalStyle`
  span[data-id='failure_notice'] {
    opacity: 0;
    display: none !important;
    z-index: -1 !important;
  }
`

const SupportOverlay = ({ enabled = true }) => {
  const router = useRouter()
  const path = router?.pathname ?? ''
  const openFromUrl = router?.query?.chat === 'support'
  const { i18n } = useTranslation()
  const [lang, setLang] = useState()
  const [show, setShow] = useState()
  const { open, close } = useCrisp()
  useEffect(() => {
    setLang(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    if (lang !== 'en') return
    if (!openFromUrl) {
      setShow(false)
      close()
    } else {
      setShow(true)
      open()
    }
  }, [openFromUrl, path, close, open, lang])

  if (!enabled) return <HideCrisp />
  return (
    <>
      {lang === 'zh' ? (
        <>
          <HideCrisp />
          <WeChat setShow={setShow} show={show} />
        </>
      ) : (
        <HideCrispInvalidSite />
      )}
      {lang === 'fr' && <HideCrisp />}
    </>
  )
}

export default SupportOverlay
